



































import { Component, Vue } from 'vue-property-decorator'

@Component({
  components: {},
})
export default class ViewFiles extends Vue {
  goToISuite() {
    this.$router.push({ name: 'ISuite' })
  }

  async askCreateiSuite() {
    const res = await this.$store.dispatch('askCreateiSuite')
    this.$store.dispatch('toaster/toast', res)
  }

  get isAdmin() {
    return this.$store.getters.isAdmin
  }
}
